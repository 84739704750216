import React from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ReactMarkdown from "react-markdown";
import "./firstComponent.scss";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../TitleDescCarousel/TitleDescCarousel";
import CMOButton from "../Button/Button";
import IconCardComponent from "../IconCardComponent/IconCardComponent";
import rehypeRaw from "rehype-raw";

const FirstComponent = (props) => {
  const {
    primary_color,
    secondary_color,
    content,
    image,
    image_mobile,
    full_width,
    productCategoriesToCard
  } = props;

  const relatedProductsSliderSettings = {
    infinite: (content.products?.length ?? 0 ) > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "cashregister-related-products-slider",
    lazyload: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: true,
          infinite: true,
        },
      },
    ],
  };

  const resolveProductCategorySlug = (id) => {
    return content.productCategories.find((category) => category._id === id)
      ?.slug;
  };

  return (
    <div className="firstComponent-wrapper">
      <div
        className="decoration"
        style={
          primary_color && secondary_color
            ? {
                background: `radial-gradient(16.98% 36.85% at 32.74% 89.18%, ${secondary_color} 0%, ${primary_color} 100%)`,
              }
            : primary_color
            ? { backgroundColor: primary_color }
            : null
        }
      >
        {
          <div className="subpage">
            <div className="firstComponent-content" style={{ padding: 0 }}>
              <section style={{ padding: "20px 0px 20px 50px" }}>
                <h1>{content?.title}</h1>
                <div className="description">
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content?.description}
                  />
                </div>
                { 
                  content.contentButton ? 
                  <div className="content-button" >
                    <CMOButton
                      onClick={ content.contentButton.onClick }
                      variant="contained"
                      background_color={"#5107ff"}
                      text={content.contentButton.text}
                      color={"white"}/>
                    </div>
                  : "" }
              </section>
              <section className="section-image">
                <div
                  className="firstComponent-image"
                  style={full_width ? { width: "100%", height: "100%" } : {}}
                >
                  <img
                    className="ill-image desktop"
                    src={`${process.env.GATSBY_CMS_URL}${image}`}
                    alt="ill_image"
                    style={
                      full_width
                        ? {
                            height: "100%",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }
                        : {}
                    }
                  />
                  <img
                    className="ill-image mobile"
                    src={`${process.env.GATSBY_CMS_URL}${image_mobile}`}
                    alt="ill_image_mobile"
                    style={
                      full_width
                        ? {
                            height: "100%",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }
                        : {}
                    }
                  />
                </div>
              </section>
            </div>
          </div>
        }
      </div>
      {content.products?.length && <h2 className="offers">Ajánlataink</h2>}
      {content.products?.length && (
        <div className="related-products">
          <Slider {...relatedProductsSliderSettings}>
            {content.products.map((relatedProduct, index) => (
              <a
                href={
                  resolveProductCategorySlug(relatedProduct.productCategory) +
                  relatedProduct.Slug
                }
                className="related-product"
                key={`related-product-${index}`}
              >
                <img
                  alt={relatedProduct.title}
                  className="related-product-image"
                  src={
                    process.env.GATSBY_CMS_URL +
                    relatedProduct.ImageColorVariant[0].image.url
                  }
                  title={relatedProduct.ImageColorVariant[0].image_description}
                  loading="lazy"
                ></img>
                <span className="related-product-name">
                  {relatedProduct.title}
                </span>
                <span className="related-product-description">
                  {relatedProduct.carouselDescription}
                </span>
                <span className="related-product-price">
                  {relatedProduct.carouselPrice}
                </span>
                <CMOButton
                  variant="contained"
                  background_color={"#5107ff"}
                  text={"Válaszd ezt!"}
                  color={"white"}
                ></CMOButton>
                <ul className="bullet-points">
                  {relatedProduct.carouselBulletPoint.map(
                    (bulletPoint, index) => (
                      <li
                        className="bullet-point"
                        key={`bullet-point-${index}`}
                      >
                        {bulletPoint.text}
                      </li>
                    )
                  )}
                </ul>
              </a>
            ))}
          </Slider>
        </div>
      )}
      {productCategoriesToCard && productCategoriesToCard.length ? (
        <div className="category-cards">
          {productCategoriesToCard.map((category, index) => (
            <IconCardComponent
              key={`category-` + index}
              category={category}
            ></IconCardComponent>
          ))}
        </div>
      ) : (
        ""
      )}
      
    </div>
  );
};

export default FirstComponent;
