import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ReactMarkDown from "react-markdown";
import FirstComponent from "../../components/FirstComponent/FirstComponent";

import account_balance_wallet from '../../assets/images/qvik/account_balance_wallet.svg';
import shopping_cart_checkout from '../../assets/images/qvik/shopping_cart_checkout.svg';
import payments from '../../assets/images/qvik/payments.svg';
import local_convenience_store from '../../assets/images/qvik/local_convenience_store.svg';
import map from '../../assets/images/qvik/map.svg';
import receipt_long from '../../assets/images/qvik/receipt_long.svg';
import request_quote from '../../assets/images/qvik/request_quote.svg';
import savings from '../../assets/images/qvik/savings.svg';
import electric_bolt from '../../assets/images/qvik/electric_bolt.svg';

import "./qvik.scss";

const Qvik = (props) => {
  const {
    FirstComponentContent,
    SEO,
    BundlePropositions,
    Component_1,
    Component_2,
    Component_3,
    Component_4,
    Component_5,
    Component_6,
    Component_Tiles
  } = props.pageContext.content;

  const location_href = props.location.pathname;

  const icons = [
    shopping_cart_checkout,
    payments,
    request_quote,
    receipt_long,
    local_convenience_store,
    map,
  ]

  const Component_4_icons = [
    savings,
    electric_bolt
  ]


  return (
    <div className="qvik-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#04BDB1"}
        header_secondary_color={"#18EECD"}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
        <FirstComponent
          primary_color={"#04BDB1"}
          secondary_color={"#18EECD"}
          content={{ ...FirstComponentContent }}
          image={FirstComponentContent.image.url}
          image_mobile={FirstComponentContent.image_mobile.url}
          subpage={true}
          big_image
        />
        <div className="blue-background-section" style={{ marginTop: "20px" }}>
          <div className="blue-background-wrapper">
            <div className="left-image">
              <img src={Component_1.SideImage ? process.env.GATSBY_CMS_URL + Component_1.SideImage?.url : ""} alt="kep" style={{ maxWidth: '300px', height: 'auto' }} />
            </div>
            <div className="right-content">
              <h2 className="title">{Component_1.Title}</h2>
              <div className="content">
                <ReactMarkDown
                  rehypePlugins={[rehypeRaw]}
                  children={Component_1.Description}
                />
              </div>

              <div className="bottom-img-container">
                <img src={Component_1.Image ? process.env.GATSBY_CMS_URL + Component_1.Image?.url : ""} alt="kep" style={{ marginTop: '20px' }} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bundles-block">
            <div className="content">
              <h2 className="title">{BundlePropositions.Title}</h2>
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={BundlePropositions.Description}
                />
              </div>

            </div>
            <div className="bundles-section">
              {BundlePropositions.BundlePropositionItem.map((item, index) => (

                <div className="bundle" key={`bundle-${index}`}
                >
                  <div className="bundle-title" style={{ backgroundColor: item.HeaderBackgroundColor, color: item.HeaderTextColor }} >
                    <h3>{item.Name}</h3>
                  </div>
                  <div className="bundle-content" >
                    <div className="bundle-image">
                      <img src={`${process.env.GATSBY_CMS_URL}${item.BundlePropositionImage?.url}`} alt={item.BundlePropositionImage?.alternativeText} style={{ width: "160px", height: "160px" }} />
                    </div>
                    <div className="bundle-description-wrapper">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.BundlePropositionDescription}
                      />
                    </div>
                  </div>
                  <div className="bundle-links">
                    <CMOButton
                      onClick={() => navigate(item.ButtonLink)}
                      variant="contained"
                      className="bundle-links"
                      background_color={"#5107ff"}
                      text={item.ButtonText}
                      color={"white"} />
                  </div>
                </div>
              ))}
            </div>
          </div>           */}
        <div className="component-2">
          <div className="component-2-img-container" style={{
            backgroundImage: `url(${process.env.GATSBY_CMS_URL}${Component_2.BackgroundImage.url})`,
          }} />
          <div className="component-2-content-container">

            <div className="title">
              <h2 className="title">{Component_2.Title}</h2>
            </div>
            <div className="description">
              <ReactMarkDown
                rehypePlugins={[rehypeRaw]}
                children={Component_2.Description}
              />
            </div>
            {/*<CMOButton 
              background_color={"#5107ff"}
              color={"#FFFFFF"}
              text={Component_2.ButtonText} 
              onClick={ () => {navigate(Component_2.ButtonLink);} } />
              */ }
          </div>
        </div>
        <div className="tilesBlock">
          <div className="tilesBlock-content">
            <h2>{Component_Tiles.Title}</h2>

            <div className="tilesBlock-tiles-container ">
              {Component_Tiles.Tiles.map((itm, idx) => {
                return (
                  <div className="tilesBlock-tile tilesBlock-tile-alternating-color" key={`tile-${idx}`} >
                    <div className="tile-icon">
                      <img src={icons[idx]} style={{height: "48px", width: "48px"}} />
                    </div>
                    <div className="tile-title">{itm.Title}</div>
                    <ReactMarkdown
                      className="tile-content"
                      rehypePlugins={[rehypeRaw]}
                      children={itm.RichTextDescription}
                    />
                  </div>
                )
              })}

            </div>
          </div>
        </div>
        <div className="component-3">
          <div className="component-3-wrapper">
            <div className="component-3-header">
              <h2>{Component_3.Title}</h2>
              <ReactMarkdown className="component-3-section-description" rehypePlugins={[rehypeRaw]} children={Component_3.Description} />
            </div>
            {Component_3.Sections.map((itm, idx) => {
              return (
                <div className="component-3-section-wrapper" key={`section-${idx}`}>
                  <div className="imgContainer"
                    style={{
                      backgroundImage: `url(${process.env.GATSBY_CMS_URL}${itm.Image?.url})`,
                    }} />
                  <div className="contentContainer">
                    <ReactMarkdown className="component-3-section-title" rehypePlugins={[rehypeRaw]} children={itm.FormattedTitle} />
                    <ReactMarkdown
                      className="component-3-section-description"
                      rehypePlugins={[rehypeRaw]}
                      children={itm.Description}
                    />
                  </div>
                </div>

              )
            })}
          </div>
        </div>
        <div className="blue-background-section">
          <div className="blue-background-wrapper">
            <div className="right-content">
              <div className="title">{Component_3.FollowupTitle}</div>
              <ReactMarkdown
                className="content"
                rehypePlugins={[rehypeRaw]}
                children={Component_3.FollowupDescription}
              />
            </div>
          </div>
        </div>
        <div className="component-4">
          
            {Component_4.Blocks.map((itm, idx) =>{
              return (<div className={`component-4-block ${idx % 2 ? "gray" : "green" }`}  key={`component-4-block-${idx}`}>
                <div className={`component-4-content-container ${idx % 2 ? "flex-row-reverse" : ""}`}>
                  <div className="img-block">
                    <img src={itm.Image ? process.env.GATSBY_CMS_URL + itm.Image?.url : ""} alt="kep" style={{ maxWidth: '500px', width: '-webkit-fill-available', height: 'auto', backgroundSize: "cover" }} />
                  </div>
                  <div className="text-block">
                    <div className="icon">
                      <img src={Component_4_icons[idx]} style={{ fill: "red", height: "48px", width: "48px ", color: "blue" }} />
                    </div>
                    <div className="title">{itm.Title}</div>
                    <ReactMarkdown
                      className="content"
                      rehypePlugins={[rehypeRaw]}
                      children={itm.Description}
                    />
                  </div>
                </div>
              </div>)
            } )}
          
        </div>
        <div className="tilesBlock">
          <div className="tilesBlock-content">
            <div className="tilesBlock-tiles-container">
              {Component_5.ComponentContentItem.map((itm, idx) => {
                return (
                  <div className="tilesBlock-tile" key={`tile-${idx}`}>
                    <div className="tile-title">{itm.Title}</div>
                    <ReactMarkdown
                      className="tile-content"
                      rehypePlugins={[rehypeRaw]}
                      children={itm.Description}
                    />
                  </div>
                )
              })}

            </div>
          </div>
        </div>
        
        <div loading="lazy"
          className="component-6"
          style={{
            backgroundImage: `url(${process.env.GATSBY_CMS_URL}${Component_6.Image.url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}>
          <LayoutComponent>
            <div className="component-6-content">
                <div className="title">
                  {Component_6.Title}
                </div>
                <div className="description">
                  <ReactMarkDown
                    rehypePlugins={[rehypeRaw]}
                    children={Component_6.Description}
                  />
                </div>
            </div>

              {/*<CMOButton 
              background_color={"#5107ff"}
              color={"#FFFFFF"}
              text={Component_2.ButtonText} 
              onClick={ () => {navigate(Component_2.ButtonLink);} } />
              */ }
          </LayoutComponent>
        </div>
      </LayoutHeaderFooter>
    </div>


  );
};

export default Qvik;
